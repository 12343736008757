import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { TrashOutlinedIcon } from '@getgo/chameleon-icons/react';
import { ChipV2, IconButton, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { showErrorSnack } from 'modules/error';
import { partnerType } from 'modules/global-wrapper';
import {
  postPaymentMethods,
  postPaymentMethodsDelete,
  postPaymentMethodsSetDefault,
  setPaymentType,
} from 'modules/payment-methods';
import st from 'utils/shared-translations';

import './default-delete-ctas.css';

interface DefaultDeleteCTAsProps {
  paymentType: string;
  isDefault: boolean;
  paymentMethodKey: string;
  isDeletable: boolean;
}

const DefaultDeleteCTAs: FC<DefaultDeleteCTAsProps> = ({ paymentType, isDefault, paymentMethodKey, isDeletable }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [isAPILoading, setAPILoading] = useState(false);

  const selectedPartnerType = useAppSelector(partnerType);

  /**
   * This method handles both API call (delete and set as default)
   * based on the passed action.
   */
  const handleAPICall = (action: Function) => {
    const payload = { paymentMethodKey };

    setAPILoading(true);
    dispatch(action(payload))
      .unwrap()
      .then(() => {
        setAPILoading(false);
        dispatch(postPaymentMethods())
          .unwrap()
          .then(() => dispatch(setPaymentType(paymentType)))
          .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
      })
      .catch(() => {
        setAPILoading(false);
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
      });
  };

  return (
    <div className="default-delete-ctas">
      {isDefault ? (
        <ChipV2 aria-label="primary bank" className="default-delete-ctas__primary" size="small">
          <Typography
            tag="span"
            className="default-delete-ctas__chip-text"
            variant="caption-medium-strong"
            color="type-color-inverted"
          >
            {intl.formatMessage(st['payment.methods.default'])}
          </Typography>
        </ChipV2>
      ) : (
        <ChipV2
          type="button"
          size="small"
          aria-label="set as primary payment method button"
          disabled={isAPILoading || selectedPartnerType === 'PARENT'}
          onAction={() => handleAPICall(postPaymentMethodsSetDefault)}
        >
          <Typography tag="span" className="default-delete-ctas__chip-text" variant="caption-medium-strong">
            {intl.formatMessage(st['payment.methods.cta.setdefault'])}
          </Typography>
        </ChipV2>
      )}
      {isDeletable && (
        <IconButton
          disabled={isAPILoading || selectedPartnerType === 'PARENT'}
          size="medium"
          variant="primary"
          label="delete bank account"
          onClick={() => handleAPICall(postPaymentMethodsDelete)}
        >
          <TrashOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
};

export default DefaultDeleteCTAs;
