import { FC } from 'react';
import { useIntl } from 'react-intl';
import { BankAccountOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import ACHDDBuyNow from 'components/ach-dd-buy-now';
import DefaultDeleteCTAs from 'components/default-delete-ctas';
import { AchDetails } from 'types/payment-method';
import { ACH } from 'utils/constants';
import st from 'utils/shared-translations';

import './ach-info.css';

interface ACHInfoProps {
  achDetails: AchDetails;
}

const ACHInfo: FC<ACHInfoProps> = ({ achDetails }): JSX.Element => {
  const intl = useIntl();

  return (
    <section className="ach-info">
      <div className="ach-info__header">
        <BankAccountOutlinedIcon size="24px" />
        <DefaultDeleteCTAs
          isDefault={achDetails.isDefault}
          isDeletable={achDetails.isDeletable}
          paymentMethodKey={achDetails.paymentMethodKey}
          paymentType={ACH}
        />
      </div>
      <Typography tag="h3" variant="body-small" className="ach-info__number">
        {intl.formatMessage(st['ach.bank.account.number.endingin'])} {achDetails?.last4Digits}
      </Typography>

      <ACHDDBuyNow paymentDetails={achDetails} paymentType={ACH} />
    </section>
  );
};

export default ACHInfo;
